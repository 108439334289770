<script lang="ts">
    import { onDestroy } from 'svelte';
    import SearchBox from '@/Components/Search/SearchBox.svelte';
    import { Fire, Clock, Users, Photo, VideoCamera, Icon, ArrowLeft, Funnel } from 'svelte-hero-icons';
    import LoadingSpinner from '@/Components/Search/LoadingSpinner.svelte';
    import UserWrapper from '@/Components/Search/Users/UserWrapper.svelte';
    import PostsWrapper from '@/elements/Feed/PostsWrapper.svelte';
    import SearchFilters from '@/Components/Search/SearchFilters.svelte';
    import type { User } from '@/interfaces/types';
    import StreamsWrapper from '@/elements/Feed/StreamsWrapper.svelte';
    import SuggestionsBox from '@/Components/SuggestionsBox.svelte';
    import CheckoutBox from '@/Components/Search/CheckoutBox.svelte';
    import { inertia } from '@inertiajs/svelte';
    import NoContentAvailable from '../../../../public/img/no-content-available.svg';
    import { loadMoreSearchResults, initializeSearchResults } from '@/utils/search-loader';
    import { getStoreForFilter, resetAllSearchStores, initializeSearchStore } from '@/stores/search-pagination';
    import { derived } from 'svelte/store';
    import { infiniteScroll } from '@/actions/infinite-scroll';

    interface PaginatorConfig {
        next_page_url: string | null;
        prev_page_url: string | null;
        current_page: number;
        total: number;
        per_page: number;
        hasMore: boolean;
    }

    interface JSData {
        searchType: 'people' | 'streams' | 'feed' | null;
        paginatorConfig: PaginatorConfig | null;
        initialPostIDs?: number[];
    }

    export let data;
    export let searchTerm;
    export let suggestions;
    export let availableFilters;
    export let activeFilter;
    export let authUser: User;
    export let reportStatuses: string[];
    export let jsData: JSData;
    export let feedDisableRightClickOnMedia;
    export let paymentData;
    export let theme;

    let searchType = jsData?.searchType || null;

    let paginatorConfig = jsData?.paginatorConfig || null;

    $: initialPostIDs = searchType === 'feed' ? jsData?.initialPostIDs || [] : [];

    $: console.log('Search type:', searchType, 'Config:', paginatorConfig);

    let nextPageUrl: string = '';

    $: nextPageUrl = paginatorConfig?.next_page_url || '';

    let paymentSettings = {
        stripeSecretKey: paymentData.stripeSecretKey,
        stripePublicKey: paymentData.stripePublicKey,
        isStripeCheckoutDisabled: paymentData.isStripeCheckoutDisabled,
        paypalClientId: paymentData.paypalClientId,
        paypalSecret: paymentData.paypalSecret,
        isPaypalCheckoutDisabled: paymentData.isPaypalCheckoutDisabled,
        coinbaseApiKey: paymentData.coinbaseApiKey,
        isCoinbaseCheckoutDisabled: paymentData.isCoinbaseCheckoutDisabled,
        nowPaymentApiKey: paymentData.nowPaymentApiKey,
        isNowPaymentsCheckoutDisabled: paymentData.isNowPaymentsCheckoutDisabled,
        isCCbillCredentialsProvided: paymentData.isCCbillCredentialsProvided,
        paystackSecretKey: paymentData.paystackSecretKey,
        isPaystackCheckoutDisabled: paymentData.isPaystackCheckoutDisabled,
        isStripeOXXOProviderEnabled: paymentData.isStripeOXXOProviderEnabled,
        appTheme: paymentData.appTheme,
        defaultUserTheme: paymentData.defaultUserTheme,
        minimumTimValue: paymentData.minimumTimValue,
        maximumTipValue: paymentData.maximumTipValue,
        currencySymbol: paymentData.currencySymbol,
        paystactSecretKey: paymentData.paystactSecretKey,
        walletTotal: paymentData.walletTotal,
        allowProfileQRCode: paymentData.allowProfileQRCode,
        disableProfileBioExcerpt: paymentData.disableProfileBioExcerpt,
        allowUsersEnablingOpenProfiles: paymentData.allowUsersEnablingOpenProfiles,
        currencyCode: paymentData.currencyCode,
        enforceEmailValidation: paymentData.enforceEmailValidation,
        minPostDeletionLimit: paymentData.minPostDeletionLimit,
    };

    export let cookiesPrev;
    export let postPrev;
    export let sidebar_ad_spot;
    let searchFilters = data?.searchFilters || {};
    let streams = data?.streams;
    let isFilterOpen = false;
    const toggleFilter = () => {
        isFilterOpen = !isFilterOpen;
    };

    const icons: any = {
        top: Fire,
        latest: Clock,
        people: Users,
        photos: Photo,
        videos: VideoCamera,
    };

    const getIcon = (filter: string) => {
        return icons[filter] ?? Fire;
    };

    $: if (data && activeFilter) {
        initializeSearchResults(activeFilter, data.posts?.data || data.users || [], jsData.paginatorConfig);
    }

    $: if (activeFilter !== 'people' && jsData) {
        initializeSearchStore(activeFilter, data?.posts?.data || [], jsData.paginatorConfig);
    }

    $: currentStore = getStoreForFilter(activeFilter);

    $: isLoading = derived(currentStore, ($store) => $store.loading);

    onDestroy(() => {
        resetAllSearchStores();
    });

    console.log('Paginator Config', paginatorConfig);
    console.log('data', data);
</script>

<div>
    <div class="flex">
        <div class="w-full p-0 md:w-7/12 lg:w-8/12">
            <div class="fixed-top-m feed-mobile-search flex bg-gray-50 px-3 py-3">
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <!-- svelte-ignore a11y-no-static-element-interactions -->
                <span
                    class="h-pill h-pill-primary search-back-button flex items-center justify-center rounded"
                    on:click="{() => {
                        window.location.href = '/feed';
                    }}"
                >
                    <Icon src="{ArrowLeft}" size="24px" />
                </span>
                <div class="col pl-2">
                    <SearchBox {searchTerm} {activeFilter} {searchFilters} />
                </div>
                {#if activeFilter === 'people'}
                    <!-- svelte-ignore a11y-interactive-supports-focus -->
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <span
                        class="h-pill h-pill-primary search-back-button flex items-center justify-center rounded"
                        role="button"
                        on:click="{toggleFilter}"
                    >
                        <Icon src="{Funnel}" size="24px" />
                    </span>
                {/if}
            </div>
            <div class="py-2 md:pt-72">
                {#if activeFilter === 'people' && isFilterOpen}
                    <div class="mobile-search-filter px-2 py-2">
                        <SearchFilters genders="{data?.genders}" {searchFilters} {searchTerm} {activeFilter} />
                    </div>
                {/if}
                <div class="inline-border-tabs mt-3">
                    <nav class="nav nav-pills nav-justified bookmarks-nav">
                        {#each Object.entries(availableFilters) as [key, filter]}
                            <!-- svelte-ignore a11y-missing-attribute -->
                            <a
                                class="nav-item nav-link {filter === activeFilter ? 'active' : ''}"
                                href="{`/search?${searchTerm ? `query=${searchTerm}&` : ''}filter=${filter}`}"
                                use:inertia
                            >
                                <div class="flex justify-center font-bold">
                                    <span class="d-md-none">
                                        <Icon src="{getIcon(String(filter))}" size="24px" />
                                    </span>

                                    {#if filter === 'live'}
                                        <div class="blob red hidden md:block"></div>
                                    {/if}

                                    <span class="hidden capitalize md:block">
                                        {filter}
                                    </span>
                                </div>
                            </a>
                        {/each}
                    </nav>
                </div>
            </div>

            <!-- @include('elements.message-alert', ['classes' => 'p-2']) -->

            <!-- <MessageAlert /> -->

            {#if activeFilter === 'people'}
                {#if currentStore?.items?.length > 0}
                    <div class="mt-4">
                        <UserWrapper users="{currentStore.items}" {theme} />
                        <div
                            use:infiniteScroll="{{ callback: () => loadMoreSearchResults('people') }}"
                            class="load-more-trigger"
                        ></div>
                    </div>
                    {#if $currentStore.loading}
                        <LoadingSpinner />
                    {/if}
                {:else}
                    <div class="flex items-center justify-center">
                        <h5 class="mb-2 mt-2 text-center">No users available</h5>
                    </div>
                {/if}
            {:else}
                <!-- New template for other filters using pagination stores -->
                <div
                    class="feed-container"
                    use:infiniteScroll="{{ callback: () => loadMoreSearchResults(activeFilter) }}"
                >
                    {#if $currentStore?.items?.length > 0}
                        <div class="feed-box posts-wrapper mt-0 pt-2">
                            <PostsWrapper data="{$currentStore.items}" {authUser} {reportStatuses} {paymentSettings} />
                        </div>

                        {#if $currentStore.loading}
                            <div class="loading-spinner">
                                <LoadingSpinner />
                            </div>
                        {/if}

                        {#if $currentStore.error}
                            <div class="error-message p-4 text-center text-red-500">
                                {$currentStore.error}
                            </div>
                        {/if}
                    {:else}
                        <div class="flex items-center justify-center">
                            <div class="col-10">
                                <img src="{NoContentAvailable}" alt="No content available" />
                            </div>
                        </div>
                        <div class="flex items-center justify-center">
                            <h5 class="mb-2 mt-2 text-center">No posts available</h5>
                        </div>
                    {/if}
                </div>
            {/if}

            {#if streams}
                <div class="streams-box streams-wrapper mt-4">
                    <StreamsWrapper {streams} />
                </div>

                {#if $isLoading}
                    <LoadingSpinner />
                {/if}
            {/if}
        </div>
        <div class="suggestions-wrapper hidden min-h-screen w-full border-l pb-5 pt-4 md:block md:w-5/12 lg:w-4/12">
            <div class="search-widgets">
                <SuggestionsBox {suggestions} isMobile="{false}" />

                {#if sidebar_ad_spot !== null}
                    <div class="mt-4">
                        {sidebar_ad_spot}
                    </div>
                {/if}
            </div>
        </div>
    </div>

    <CheckoutBox {paymentSettings} />
</div>

<style>
    .loading-spinner {
        display: flex;
        justify-content: center;
        padding: 2rem;
    }
</style>
